$(document).ready(function () {
  let organizer_new_ajax_lock = false;

  $(document).on("submit", "#organizer_register_form", function (e) {
    e.preventDefault();
    $("#organizer_register_button").attr("disabled", true);
    $("#btn-text").addClass("d-none");
    $("#loader-text").removeClass("d-none");
    $("#message").remove();

    if (!organizer_new_ajax_lock) {
      organizer_new_ajax_lock = true;

      let formData = new FormData(
        document.getElementById("organizer_register_form")
      );
      $.ajax({
        type: "POST",
        url: "/ajax/organizer/new",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.authToken !== undefined && data.authToken !== null) {
            if (!$("#organizer_register_form").hasClass("d-none")) {
              $("#organizer_register_form").addClass("d-none");
            }
            if ($("#organizer_register_success_message").hasClass("d-none")) {
              $("#organizer_register_success_message").removeClass("d-none");
            }

            $("#btn-text").removeClass("d-none");
            $("#loader-text").addClass("d-none");
          } else {
            document.location.href =
              "/login?email=" +
              $("input[name='contact_email']").val() +
              "&user=existing&organizer=new";
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            $("#organizer_register_button").attr("disabled", false);
            $("#btn-text").removeClass("d-none");
            $("#loader-text").addClass("d-none");
            prependAlert(
              "#div_organizer_register_button",
              data.responseJSON.error_message,
              "danger",
              null,
              "#organizer_register_form"
            );
          }

          organizer_new_ajax_lock = false;
        })
        .always(function () {
          organizer_new_ajax_lock = false;
        });
    }

    return false;
  });
});
