$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (window.ReactNativeWebView) {
    $.ajax({
      url: "/api/v1/check-login",
      method: "GET",
      dataType: "json",
    })
      .done(function (data) {
        if (data && data.isLoggedIn) {
          orderPrintInterval = setInterval(function () {
            getOrderToPrint();
          }, 5000);
        }
      })
      .fail(function (xhr, status, error) {
        console.error("Error fetching login status:", error);
      });
  }
});
